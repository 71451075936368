import Accordion from "react-bootstrap/Accordion";

function Portfolio() {
    return (
        <div className="scrollableSection" id="portfolio">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <div className="h1Container">
                    <div className="h1ContainerOverlay">
                        <h1>
                            Portfolio
                        </h1>
                    </div>
                </div>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        Experience
                    </Accordion.Header>
                    <Accordion.Body>
                        <h3>
                            NextGen Healthcare
                        </h3>
                        <h4>
                            Senior Engineer, Information Services
                        </h4>
                        <h5>
                            June 2023 - PRESENT (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Strategize hosting and deployment solutions for DevOps and Migration teams,
                                    resulting in streamlined operations and improved efficiency.
                                </li>
                                <li>
                                    Automate and deploy cloud applications, services and infrastructure, enhancing
                                    scalability and reliability while reducing manual workload.
                                </li>
                                <li>
                                    Build and maintain tools to support the deployment of healthcare services in
                                    AWS, optimizing service delivery and user experience.
                                </li>
                            </ul>
                        </p>
                        <h3>
                            Stanley PMI
                        </h3>
                        <h4>
                            Web Operations Lead
                        </h4>
                        <h5>
                            March 2021 - June 2023 (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Serve as the business technology subject matter expert including features and functionality of our “direct-to-consumer” sites and other
                                    corporate websites, which continue to break year-over-year growth records of Stanley products internationally.
                                </li>
                                <li>
                                    Collaborate with business partners, clients, vendors, and stakeholders to identify project requirements and ensure project goals are met in our
                                    fast-growing “direct-to-consumer” business, resulting in streamlined cross-functional processes between eCommerce and the technology
                                    teams to support rapid growth.
                                </li>
                                <li>
                                    As account owner of our enterprise customer support solution, I migrated our customer service platforms from small-business operations to a
                                    global solution for all of our Stanley 1913 stores internationally. This replaced archaic processes for our customer support teams, cutting our
                                    SLA time by over 60% for our US, UK and EU operations.
                                </li>
                                <li>
                                    Architect, document, and train new infrastructure processes cross-collaboratively for all web-related operations, which expedites onboarding
                                    overhead for rapidly growing teams throughout the organization.
                                </li>
                            </ul>
                        </p>
                        <h3>
                            University of Washington
                        </h3>
                        <h4>
                            Senior Consultant, Statistics, PhD Program
                        </h4>
                        <h5>
                            March 2021 - September 2021 (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Build a collaborative and comprehensive documentation wiki for administrators
                                    and developers.
                                </li>
                                <li>
                                    Conduct interviews and provide hiring consultation.
                                </li>
                                <li>
                                    Continue to prioritize Drupal and internal web application maintenance while
                                    constructing a roadmap for future development.
                                </li>
                            </ul>
                        </p>
                        <h4>
                            Senior Web Developer, Statistics, PhD Program
                        </h4>
                        <h5>
                            December 2018 - March 2021 (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Architect, supervise, and develop internal applications for the Statistics PhD program, consisting of a series of PHP/MySQL based applications
                                    which assist with reviewing applicants, student advising and student employee evaluations. This set of tools provides various users to
                                    self-serve via automated processes and replace routine manual analysis.
                                </li>
                                <li>
                                    Integrate ORCID, a persistent digital identifier vendor, to allow students, faculty and researchers present their preprint publications and
                                    promote their research on the UW Statistics Department website. As of late 2020, this project makes our department the first on campus to
                                    take this to production!
                                </li>
                                <li>
                                    Architect production site hosting with Docker-based processes, which resoundingly reduced deployment complexities and provided a
                                    consistent development and deployment experience for our entire department.
                                </li>
                                <li>
                                    Lead multiple website migrations, including moving our legacy web applications to Drupal 9. This process includes building custom modules
                                    for syncing data from various API services on campus, leading to a faster and secure web application capable of deeper integration with
                                    various data points throughout the university.
                                </li>
                                <li>
                                    Routinely coordinate with developers across campus to present and discover new development strategies, covering topics such as Drupal
                                    development, reporting tactics, data security, and deployment processes.
                                </li>
                            </ul>
                        </p>
                        <h3>
                            FDB Health (Hearst)
                        </h3>
                        <h4>
                            PHP Developer (PHP/Postgres)
                        </h4>
                        <h5>
                            October 2016 - December 2018 (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    As client implementation lead, I work directly with hospital/ambulatory software vendors and their environments on troubleshooting, software
                                    updates, building solutions, and providing custom reports.
                                </li>
                                <li>
                                    Integrate SOAP and RESTful API services for single sign-on (SSO), patients, allergies, medications, and encounters between each vendor
                                    application and their embedded instance of MedsTracker®.
                                </li>
                                <li>
                                    Implement Solr for efficient retrieval of insurance formulary information, which enhances search picklists and provides accurate geolocation for
                                    pharmacies and clinics.
                                </li>
                                <li>
                                    “Dockerizing” our dev and QA environments locally for development and in AWS Elastic Container Services and enhancing cross-collaboration
                                    between teams. Build custom web containers to better mirror our deployment environments and improve performance.
                                </li>
                                <li>
                                    Roadmapped and executed on legacy code enhancements (PHP5 -> PHP7.1 migration, autoloading, feature branch automation).
                                </li>
                                <li>
                                    Implement Two-factor authentication for EPCS identity proofing and ePrescribing - Imprivata, Exostar. This enhances security and patient
                                    privacy for all of our ambulatory and hospital vendors.
                                </li>
                                <li>
                                    As part of moving our team to agile, I led Jira API automation for version control integration and deployment management which streamlines
                                    our sprint processes and automates deployment pipelines.
                                </li>
                            </ul>
                        </p>
                        <h3>
                            Super Lawyers (Thomson Reuters)
                        </h3>
                        <h4>
                            Web Application Developer
                        </h4>
                        <h5>
                            October 2013 - October 2016 (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Led and executed the development of SuperLawyers.com relaunch (and family sites). With the new version of PHP and MVC design patterns,
                                    each of the public websites reduced page load time by 80% and ranked top three in Google’s organic searches within the first week.
                                </li>
                                <li>
                                    Strategize and lead SEO markup development and implementation across all attorney profiles and the SuperLawyers.com directory.
                                    Build solutions for online store, shopping cart and affiliate tracking using PHP, MySQL and Google Analytics to drive online sales.
                                </li>
                                <li>
                                    Build solutions for online store, shopping cart and affiliate tracking using PHP, MySQL and Google Analytics to drive online sales.
                                </li>
                                <li>
                                    Architect and develop a series of CMS platforms for marketing, editorial, event planners, and product management using PHP/MySQL,
                                    CKEditor, and AWS S3 for asset management, removing bottle-neck requests for manual content creation and providing various departments
                                    self-service access to publish live content.
                                </li>
                            </ul>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        Skills
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <ul>
                                <li>
                                    Senior/Lead Full-stack Website Development
                                    <ul>
                                        <li>
                                            PHP, MySQL, Javascript, NodeJS, ReactJS
                                        </li>
                                        <li>
                                            AWS/Azure Cloud Services
                                        </li>
                                        <li>
                                            Front-End: UI/UX Design and SEO implementation
                                        </li>
                                        <li>
                                            Back-End: REST API Development and Database Architecture
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Team Leadership
                                </li>
                                <li>
                                    Project Management
                                </li>
                                <li>
                                    Accounting and Budget Management
                                </li>
                                <li>
                                    Legacy Code Migration
                                </li>
                                <li>
                                    E-Commerce Strategization
                                </li>
                                <li>
                                    QA Process Management
                                </li>
                                <li>
                                    Reporting and Analytics
                                </li>
                                <li>
                                    Network Infrastructure
                                </li>
                            </ul>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        Education
                    </Accordion.Header>
                    <Accordion.Body>
                        <h3>BA - IT Administrative Management</h3>
                        <h4>(Seattle, WA)</h4><br/>
                        <p>
                            <ul>
                                <li>
                                    IT Security
                                </li>
                                <li>
                                    Privacy and Ethics
                                </li>
                                <li>
                                    Business Math Applications
                                </li>
                                <li>
                                    Systems Analysis
                                </li>
                                <li>
                                    Leadership and Supervision
                                </li>
                                <li>
                                    Financial Analysis
                                </li>
                                <li>
                                    Project Management
                                </li>
                            </ul>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        Projects, Volunteer, and Related Experience
                    </Accordion.Header>
                    <Accordion.Body>
                        <h3>
                            Novelty Water User Association
                        </h3>
                        <h4>
                            Vice President
                        </h4>
                        <h5>
                            September 2024 - Present (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Help schedule and run annual meetings.
                                </li>
                                <li>
                                    Assist association president with organization duties as needed.
                                </li>
                            </ul>
                        </p>

                        <h3>
                            Board of Directors, Homeowners Association
                        </h3>
                        <h4>
                            Vice President & Treasurer
                        </h4>
                        <h5>
                            March 2023 - September 2024 (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Help schedule and run board meetings.
                                </li>
                                <li>
                                    Serve as a main point of contact for our association and vendors.
                                </li>
                                <li>
                                    Review contracts, financial records and invoices and ensure the schedule of timely
                                    payments.
                                </li>
                                <li>
                                    Manage CDs and investments for our reserve funds.
                                </li>
                                <li>
                                    Factor in utility price changes and reserve studies into current/future budgets.
                                </li>
                                <li>
                                    Forecast budget changes based on financial statements and operations/reserves
                                    activity.
                                </li>
                                <li>
                                    Microsoft 365 Administration.
                                </li>
                            </ul>
                        </p>

                        <h3>
                            Gaseous Content Management System
                        </h3>
                        <h4>
                            Founder and Developer
                        </h4>
                        <h5>
                            August 2020 - January 2025 (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Open-source PHP/MySQL project for building a SEO-focused content management
                                    system.<br/>
                                    <a target="_blank" rel="noreferrer nofollow"
                                       href="https://www.gaseous.org">Gaseous.org</a>
                                </li>
                            </ul>
                        </p>
                        <h3>
                            Butter London, LLC
                        </h3>
                        <h4>
                            E-Commerce/Website Development Consultant
                        </h4>
                        <h5>
                            April 2018 - April 2019 (Seattle, WA)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    NetSuite Administration
                                </li>
                                <li>
                                    SEO Enhancements
                                </li>
                                <li>
                                    Marketing integration with Bronto
                                </li>
                                <li>
                                    E-Commerce Migration (NetSuite -> Magento)
                                </li>
                                <li>
                                    SuiteCommerce API development
                                </li>
                            </ul>
                        </p>
                        <h3>
                            University of California, Santa Cruz
                        </h3>
                        <h4>
                            Genomics Institute Volunteer
                        </h4>
                        <h5>
                            May 2017 - May 2018 (Seattle, WA/Remote)
                        </h5>
                        <p>
                            <ul>
                                <li>
                                    Built an internal web application to process database exports for staff
                                    reporting and emails.
                                </li>
                                <li>
                                    Scholarship website development and maintenance (WordPress).
                                </li>
                            </ul>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default Portfolio;