import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDocker, faGithub, faAws} from '@fortawesome/free-brands-svg-icons';
import {faInfinity} from '@fortawesome/free-solid-svg-icons';
import {faBuildingUser} from '@fortawesome/free-solid-svg-icons';

function CurrentProjects() {
    return (
        <div className="scrollableSection" id="currentProjects">
            <div className="h1Container">
                <div className="h1ContainerOverlay">
                    <h1>
                        Current Projects
                    </h1>
                </div>
            </div>
            <div id="currentProjectsDetails">
                <h3><FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;Docker</h3>
                <p>
                    Docker images are continuously built and published through <a target="_blank" rel="nofollow noreferrer" href="https://github.com/jlr2k8/mydock">MyDock</a>. These scripts build the OS layer of Debian
                    (Bookworm), Apache 2.4, and PHP 8.x - each compiling images with the latest packages.
                </p>
                <p>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow noreferrer" href="https://hub.docker.com/r/joshlrogers/node">Node
                                Latest</a>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                                    <a target="_blank" rel="nofollow noreferrer"
                                       href="https://github.com/jlr2k8/mydock/tree/main/projects/node">projects/node</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow noreferrer" href="https://hub.docker.com/r/joshlrogers/apache">Apache
                                2.4</a>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                                    <a target="_blank" rel="nofollow noreferrer"
                                       href="https://github.com/jlr2k8/mydock/tree/main/projects/apache">projects/apache</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow noreferrer" href="https://hub.docker.com/r/joshlrogers/php8-apache">Apache
                                with PHP 8</a>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                                    <a target="_blank" rel="nofollow noreferrer"
                                       href="https://github.com/jlr2k8/mydock/blob/main/projects/php8-apache">projects/php8-apache</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faDocker}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow noreferrer" href="https://hub.docker.com/r/joshlrogers/debian">Debian
                                Bookworm</a>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                                    <a target="_blank" rel="nofollow noreferrer"
                                       href="https://github.com/jlr2k8/mydock/tree/main/projects/debian">projects/debian</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </p>
                <h3><FontAwesomeIcon icon={faInfinity}/>&nbsp;&nbsp;CICD Pipeline Automation</h3>
                <p>
                    Setting up CloudFormation to create pipelines and automate:
                    <ul>
                        <li>
                            S3 buckets for projects and users
                        </li>
                        <li>
                            EC2 instances and security groups for web servers and API servers
                        </li>
                        <li>
                            ECS serverless Fargate deployments (coming soon!)
                        </li>
                    </ul>
                </p>
                <p>
                    <ul>
                        <li>
                            View the project:<br />
                            <FontAwesomeIcon icon={faGithub}/>&nbsp;&nbsp;
                            <a target="_blank" rel="nofollow noreferrer"
                               href="https://github.com/jlr2k8/aws-cicd">projects/aws-cicd</a>
                        </li>
                    </ul>
                </p>
                <h3><FontAwesomeIcon icon={faAws}/>&nbsp;&nbsp;Automating with AWS</h3>
                <p>
                    <a target="_blank" rel="nofollow noreferrer" href="https://www.nextgen.com/">
                        <img id="nextgen-logo" src="/nextgen.png" alt="NextGen Logo" />
                    </a> At NextGen Healthcare, we build IaC CI/CD pipelines to automate and grow our hosting operations. Automating our fleet with CloudFormation scripts and Service Catalog portfolios, we are continuously implementing hosting solutions to streamline the delivery of our products.
                </p>
            </div>
        </div>
    );
}

export default CurrentProjects;